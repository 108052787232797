import request from '@/utils/request'


// 查询品牌列表
export function listBrand(query) {
  return request({
    url: '/goods/brand/list',
    method: 'get',
    params: query
  })
}

export function allBrand() {
  return request({
    url: '/goods/brand/all',
    method: 'get'
  })
}

// 查询品牌分页
export function pageBrand(query) {
  return request({
    url: '/goods/brand/page',
    method: 'get',
    params: query
  })
}

// 查询品牌详细
export function getBrand(data) {
  return request({
    url: '/goods/brand/detail',
    method: 'get',
    params: data
  })
}

// 新增品牌
export function addBrand(data) {
  return request({
    url: '/goods/brand/add',
    method: 'post',
    data: data
  })
}

// 修改品牌
export function updateBrand(data) {
  return request({
    url: '/goods/brand/edit',
    method: 'post',
    data: data
  })
}

// 删除品牌
export function delBrand(data) {
  return request({
    url: '/goods/brand/delete',
    method: 'post',
    data: data
  })
}
