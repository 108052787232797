import request from '@/utils/request'


// 查询商品标签列表
export function listLabel(query) {
  return request({
    url: '/goods/label/list',
    method: 'get',
    params: query
  })
}

export function allLabel() {
  return request({
    url: '/goods/label/all',
    method: 'get',
  })
}


// 查询商品标签分页
export function pageLabel(query) {
  return request({
    url: '/goods/label/page',
    method: 'get',
    params: query
  })
}

// 查询商品标签详细
export function getLabel(data) {
  return request({
    url: '/goods/label/detail',
    method: 'get',
    params: data
  })
}

// 新增商品标签
export function addLabel(data) {
  return request({
    url: '/goods/label/add',
    method: 'post',
    data: data
  })
}

// 修改商品标签
export function updateLabel(data) {
  return request({
    url: '/goods/label/edit',
    method: 'post',
    data: data
  })
}

export function statusLabel(data) {
  return request({
    url: '/goods/label/status',
    method: 'post',
    data: data
  })
}


// 删除商品标签
export function delLabel(data) {
  return request({
    url: '/goods/label/delete',
    method: 'post',
    data: data
  })
}
